.productfull {
    h1 {
        font-size: 50px !important;
        color: $secondbrand;
    }

    h2 {
      color: $font;
    }

    .jshop_prod_description {
        padding-top: 0px;
    }

    .prod_base_price {
        font-size: 12px;
        text-align: left !important;
    }

    .prod_price, #block_price {
        font-size: 30px !important;
    }

    .image_middle {
        img {
          height: 750px;
        }
    }

    #list_product_image_thumb {
        padding-top: 40px;
        img {
            height: 100px;
        }
    }

    .btn-success {
        background-color: $secondbrand;
        border-color: $secondbrand;
    }
    #quantity {
        max-width: 70px;
    }
}

.search {
  input {
    margin-top: 20px;
    //background: transparent;
    border: 0px;
  }

  input::after {
    content: "text";
    margin-left: -40px;
  }
}

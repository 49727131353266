.newsflash {
    font-weight: 400;
    margin-top: -80px;
    margin-bottom: 100px;
    .intro {
        border-top: 3px solid darken($secondbrand,10);
        box-shadow: 0 2px 2px rgba(183, 175, 175,7/10),0 4px 4px rgba(183, 175, 175,7/10),0 8px 8px rgba(183, 175, 175,3/10),0 16px 16px rgba(183, 175, 175,3/10),0 32px 32px rgba(183, 175, 175,1/10),0 64px 64px rgba(183, 175, 175,1/10);
        -webkit-box-shadow: 0 2px 2px rgba(183, 175, 175,7/10),0 4px 4px rgba(183, 175, 175,7/10),0 8px 8px rgba(183, 175, 175,3/10),0 16px 16px rgba(183, 175, 175,3/10),0 32px 32px rgba(183, 175, 175,1/10),0 64px 64px rgba(183, 175, 175,1/10);
        -moz-box-shadow: 0 2px 2px rgba(183, 175, 175,7/10),0 4px 4px rgba(183, 175, 175,7/10),0 8px 8px rgba(183, 175, 175,3/10),0 16px 16px rgba(183, 175, 175,3/10),0 32px 32px rgba(183, 175, 175,1/10),0 64px 64px rgba(183, 175, 175,1/10);


        .darkener {
            //background-color: rgba(0,0,0,5/10);
            background-color: $invertbg;
            color: lighten($invert,20);
            min-height: 300px;

            h4 {
                background-color: $secondbrand;
                color: lighten($secondbrand,40);
                padding: 15px;

                a {
                    color: $secondbrand;
                }
            }

            p {
                padding: 15px;
            }
        }
    }

    @media (max-width: $screen-sm) {
        margin-top: -36px !important;

        .intro {
            margin-bottom: 20px;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;

            h4 {
                font-size: 30px;
            }

            .darkener {
                min-height: auto;
            }
        }
    }

    @media (max-width: $screen-md) {

        margin-top: -80px;

        .intro {
            font-size: 16px;
            h4 {
                font-size: 22px;
            }
        }
    }

    @media (max-width: $screen-lg) {
        .intro {
            font-size: 16px;
            h4 {
                font-size: 22px;
            }
        }
    }
}
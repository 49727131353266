.alert {
    @include borderRadius(0px !important);
    margin-bottom: 0px;
    border: 0px !important;
    font-weight: 300;
    padding-left: 40px;
}

.alert-error {
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
    padding: 15px;
    padding-left: 40px;
}

.alert-notice {
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    color: #8a6d3b;
    padding: 15px;
    padding-left: 40px;
}

.alert-heading {
    text-transform: uppercase;
}

.alert-message {
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    color: #8a6d3b;
    padding: 15px;
    padding-left: 40px;
}
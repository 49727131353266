/*
    Created on : 09.03.2017, 09:30:00
    Author     : christian
*/

//Hauptfarben

$invert: #3C3C3C;
$invertbg: #ECECEC;
$brand: #48D077;
$secondbrand: #FFD64E;
$dark: #333333;
$font: black;

$blau: #4aa239;
$rot: #ED7A70;
$gelb: #FFEF77;
$gruen: #4aa239;
$box: #E4E7EA;
$font: #505050;

#comjshop_list_product {

  .pro {
    padding: 20px;

    a:hover {
      text-decoration: none;
    }
  }

  .product {
    //@include background-fixed('../images/fut_saft_bg.jpg');
    padding: 20px 0 0 0;

    .image {
      img {
        height: 300px;
      }

      .product_label {
        img {
          height: auto;
        }
      }
    }

    .product_label {
      position: absolute;
      top: 40px;
      left: 40px;
    }
  }

  .weineundgluehweine {
    @include background-fixed('../images/fut_weinundgluehwein_bg.jpg');

    .name {
      a {
        font-family: 'Lilita One', cursive;
        font-weight: 300;
        font-size: 24px;
        background-color: $rot !important;
        border: 0px;
      }
    }
  }

  .nektare {
    @include background-fixed('../images/fut_nektar_bg.jpg');

    .name {
      a {
        font-family: 'Lilita One', cursive;
        font-weight: 300;
        font-size: 24px;
        background-color: $secondbrand !important;
        border: 0px;
      }
    }
  }

  .saefte {
    @include background-fixed('../images/fut_saft_bg.jpg');

    .name {
      a {
        font-family: 'Lilita One', cursive;
        font-weight: 300;
        font-size: 24px;
        background-color: $brand !important;
        border: 0px;
      }
    }
  }
}

#mxcpr {
  display: none;
}

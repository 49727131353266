.pfinish_comment_block {
    min-width: auto !important;
    max-width: 100% !important;
    
    
    .name {
        text-align: left;
    }
    
    textarea {
        width: 100%;
        height: 200px !important;
        max-width: 100% !important;
        margin-bottom: 50px;
    }
}
.modal-content {
    @include borderRadius(0);

    .modal-title {
        text-transform: uppercase;
    }

    p {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    ul {
        list-style: none;
        padding-left: 0px;
        padding-top: 40px;
    }
}
#jshop_module_cart {
    padding-left: 47px;
    .card {
        @include borderRadius(0px);
    }
    
    .btn-info {
        background-color: $secondbrand;
        border-color: $secondbrand;
    }
}

@media (max-width: $screen-sm) {
    #jshop_module_cart {
        padding-left: 10px;
    }
}
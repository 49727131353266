.block_product {
    //border: 1px solid $dark;
    background-color: #FFFFFF;
    //padding: 30px;

    .product {
        position: relative; 
    }

    .name {
        a {
            text-decoration: none;
            color: $font;
            font-size: 35px;
            font-weight: 100;
        }
        border-bottom: 1px solid;
        text-align: left !important;
    }

    .productweight {
        font-size: 12px;
        text-align: center;
    }

    .description {
        font-size: 20px;
        text-align: left;
    }

    .old_price {
        position: absolute;
        bottom: 130px;
        right: 0px;
        background-color: $invert;
        padding: 5px;
    }

    .jshop_price {
        text-align: left !important;
        font-size: 30px !important;
    }

    .price_extra_info {
        text-align: left !important;
    }

    .productweight {
        text-align: left !important;
        font-size: 20px !important;
    }

    .base_price {
        font-size: 12px;
        text-align: left !important;
    }

    .image_block {
        img {
            max-height: 300px;
        }
    }

    .buttons {
        .btn {
            display: inline-block;
            float: right;
        }

        .btn-success {
            background-color: $secondbrand;
            border-color: $secondbrand;
        }

        .button_detail {
            background-color: $invert;
        }
    }

    .button_detail {
        color: #FFFFFF;
    }
}

.row-fluid {
    padding-bottom: 80px;
    .sblock3:first-child {
        padding-right: 50px;
    }

    .sblock3:nth-child(2) {
        padding-right: 35px;    
        padding-left: 15px;        
    }

    .sblock3:nth-child(3) {
        padding-left: 50px;
    }
}
.rezepte {
  position: relative;
  padding-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  color: #ffffff;
  font-weight: 100;
  padding-bottom: 50px;

  a {
    color: $font;
  }

  a:hover {
    text-decoration: none;
  }

  .btn-block {
    color: #fff;
    background-color: $brand;
    border: 0px;
  }

  h1 {
    color: lighten($brand, 20%);
    margin-top: 25px;
  }

  h2 {
    font-size: 24px;
    margin-top: 25px;
    color: $brand;
  }

  .introtext {
    margin-bottom: 25px;
  }

  .bg {
    @include background-fixed('../images/rezepte_bg.jpg');
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 70%;
    max-height: 674px;
    z-index: -1;
  }

  .box {
    background-color: #FFFFFF;
    @include boxShadow(0px,0px,20px,rgba(0,0,0,0.5));
  }
}

.jshop_register {
    width: auto !important;
    padding: 0px !important;

    .input-append {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;

    }
}

.other_delivery_adress {
    input {
        width: 100px !important;   
    }
}

.not_active_step {
    a {
        font-size: 20px;
        text-decoration: none;
        color: $brand;
    }
}

.jshop_prod_cart {
    .jshop_img {
        max-height: 150px;
    }
}

#jshop_menu_order {
    margin-bottom: 0px;
}

.address_block {
    .button {
        width: auto !important;
    }
}

.privacy_statement {
    font-size: 25px;
}


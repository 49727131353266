/*
Created on : 09.03.2017, 09:31:00
Author     : christian
*/

@import url('../fonts/LilitaOne-Regular.ttf');
@import url('../fonts/Roboto-Regular.ttf');
@import url('../fonts/Roboto-Bold.ttf');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #6e6e6e;
}

h1, h2 {
  font-family: 'Lilita One', cursive;
  font-weight: 300;
  text-transform: uppercase;
}

h1{
  font-size: 30px;
  color: $font;
  margin-bottom: 0px;

  p {
    color: $font;
  }
}

h2 {
  font-size: 40px;
  color: $rot;
  margin-bottom: 15px;
  margin-top: 50px;
}

h3, h4, h5, h6 {
  font-family: 'Lilita One', cursive;
  font-size: 40px;
  color: $font;
  font-weight: 100;
  margin-top: 0px;
  margin-bottom: 10px;
}

@media (max-width: $screen-sm) {

  body {
    font-size: 14px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 20px;
  }

  h4, h5, h6 {
    font-size: 20px;
  }

}

.jumbotron {
    padding-top: 0px;
    padding-bottom: 0px;

    .slidercontainer {
        max-width: 1400px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .djslider {
        //max-height: 500px;
    }

    .dj-image {
        /*        top: 0px !important;
                -webkit-transform: translateY(0%) !important;
                -ms-transform: translateY(0%) !important;
                transform: translateY(0%) !important;*/
    }

    .slide-desc {
        top: 0%;
        bottom: auto;
        //background-color: rgba(0,0,0,5/10);
        //padding-top: 5%;

        .slide-desc-text {
            padding: 0px;
        }

        .slide-desc-bg{
            background-color: transparent;
        }

        .slide-title {
            text-align: left;
            font-weight: 100;
            font-size: 50px;
            //padding-left: 20%;
            //padding-right: 20%;
            color: lighten($secondbrand,50) !important;
            line-height: 50px;
            margin-bottom: 0px;
        }

        @media (max-width: $screen-sm) {
            .slide-title {
                font-size: 30px;
            }

            .slide-text {
                display: none;
            }
        }

        .slide-text {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            p {
                margin-top: 10px;
            }
        }
    }

    .inner {
        background-color: $secondbrand;
        padding: 15px;
        color: lighten($secondbrand,50) !important;
        border-bottom: 4px solid darken($secondbrand, 20);
    }
}

@media (max-width: $screen-sm) {
    .jumbotron {

        .slide-title {
            font-size: 20px !important;
        }

        .slide-desc {
            //display: none;
        }

        .inner {
            background-color: rgba(0,0,0,6/10);
            border: 0px;
        }
    }
}

@media (max-width: $screen-md) {
    .jumbotron {

        .slide-title {
            font-size: 30px !important;
        }
    }
}
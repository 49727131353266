.btn-arrow-left {
    background-image: none !important;
    background-color: $invert;
    padding-left: 10px !important;
    color: #FFFFFF;
}

.btn-arrow-left:before {
    font-family: FontAwesome;
    content: "\f060";
}

.btn-arrow-right {
    background-image: none !important;
    background-color: $invert;
    padding-right: 10px !important;
    color: #FFFFFF;
}

.btn-arrow-right:after {
    font-family: FontAwesome;
    content: "\f061";
}

.btn-info {
    background-color: $blau;
    border-color: $blau;
}

.btn-info:hover {
    background-color: lighten($blau,10);
    border-color: $blau;
}

.btn-primary {
    background-color: $secondbrand;
    border-color: $secondbrand;
}

.btn-primary:hover {
    background-color: lighten($secondbrand,10);
    border-color: $secondbrand;
}
.nav-container{
  .container {
    padding: 0px;
  }
  .navbar {
    @include borderRadius(0);
    padding: 0;
    background-color: transparent !important;

    .navbar-collapse {
      background-color: $secondbrand;
    }

    .active {
      background-color: #E9C449;
      color: #FFFFFF !important;

      a {
        color: #B98000 !important;
      }
    }

    li {
      border-right: 0px solid lighten($invert, 60) !important;
      padding-left: 20px;
      padding-right: 20px;

      a {
        font-family: 'Lilita One', cursive;
        color: #B98000 !important;
        line-height: 2em;
      }
    }

    li:hover {
      background-color: $secondbrand;
      a {
        color: #FFFFFF !important;
      }
    }


    .navbar-brand:hover {
      background-color: transparent;
    }

    .nav-child {
      margin: 0px !important;
      @include borderRadius(0);
      background-color: $secondbrand;
      border: 0px;

      li {
        border-right: 0 !important;
      }
    }
  }
}

@media (max-width: $screen-md) {
  .nav-container {
    display: none;
  }
}

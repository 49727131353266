.leading-0, .leading-1 {
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: lighten($font,60%);

  .item-image {
    margin: 0px 25px 0px 0px !important;
  }

  .col-md-8 {
    padding-right: 55px;

    h2 {
      a {
        color: $brand;
      }
    }
  }
}

.item-title {
  a {
    color: $brand;
  }
}

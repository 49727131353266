footer {
  color: $font !important;
  background-color: $invertbg;
  @include background-fixed('../images/footer.jpg');
  margin-top: 80px;

  iframe {
    width: 100%;
    height: 300px;
  }

  .footer-menu {
    padding-top: 30px;
    font-size: 16px;
    line-height: 18px;

    .nav-link {
      padding: 0px;
    }

    a {
      color: $font;
    }

    .header {
      font-family: 'Lilita One', cursive;
      color: $font;
      font-weight: 700;
      font-size: 30px;
      padding-bottom: 8px;
    }
  }

  .copyright {
    margin-top: 50px;
    font-size: 12px;
  }
}

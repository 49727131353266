.jsshopping_universal {

  padding-top: 25px;
  padding-bottom: 50px;

  h1 {
    color: $font;
    font-size: 28px;
    margin-bottom: 25px;
  }

  .block_product {
    padding-top: 25px;
    //@include background-fixed('../images/fut_saft_bg.jpg');
    text-align: center;

    img {
      padding-bottom: 25px;
    }

    .name {
      text-align: center !important;
      border: 0px !important;
      font-family: 'Lilita One', cursive;
      font-weight: 300;
      color: #FFF;
      padding: 15px 15px 15px 15px;

      a {
        text-decoration: none;
        color: #FFF;
        font-size: 24px !important;
        line-height: 24px;
      }
    }
  }

  .product_label {
      position: relative;
      top: 20px;
      left: 35px;
  }

  .cat-3 {
      @include background-fixed('../images/fut_weinundgluehwein_bg.jpg');

      .name {
        background-color: $rot !important;
        a {
          font-family: 'Lilita One', cursive;
          font-weight: 300;
          font-size: 24px;
          background-color: $rot !important;
          border: 0px;
        }
      }
  }

  .cat-2 {
      @include background-fixed('../images/fut_nektar_bg.jpg');

      .name {
        background-color: $secondbrand !important;
        a {
          font-family: 'Lilita One', cursive;
          font-weight: 300;
          font-size: 24px;
          background-color: $secondbrand !important;
          border: 0px;
        }
      }
  }

  .cat-1 {
      @include background-fixed('../images/fut_saft_bg.jpg');

      .name {
        background-color: $brand !important;
        a {
          font-family: 'Lilita One', cursive;
          font-weight: 300;
          font-size: 24px;
          background-color: $brand !important;
          border: 0px;
        }
      }
  }
}

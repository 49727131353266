.header-container {
    padding-bottom: 20px;
    min-height: 600px;
    @include background-fixed('../images/header.jpg');


    .logo-box {
      text-align: center;
    }

    .main-logo {
      width: 350px;
      height: 180px;
      text-align: center;
    }


    .call-us {
        color: $brand;
        display: block;
    }

    .phone-number {
        color: $invert;
        font-weight: 100;
        font-size: 40px;
        display: block;
        margin-top: -14px;
    }

    @media (max-width: $screen-md) {

        img {
            //display: none;
        }

        .phone-number {
            font-size: 30px;
        }
    }

    @media (max-width: $screen-md) {
        .phone-number {
            font-size: 30px;
        }
    }
}

.header-alt {
  @include background-fixed('../images/footer.jpg');
}

.small-header {
  min-height: auto !important;
  height: 200px !important;
}

input, button, textarea, .input-group-addon {
    font-size: 26px;
    @include borderRadius(0px !important);
}

input {
    height: auto !important;
    font-size: 16px !important;
}

.btn {
    font-size: 16px;
    @include borderRadius(0px !important);
}

.control-label {
    text-align: left !important;
    font-size: 18px;
    label, .red {
        font-weight: 300 !important;
    }
}

.controls {
    input {
        display: block;
        width: 100%;
        //height: 34px;
        //padding: 6px 12px;
        //font-size: 14px;
        //line-height: 1.42857143;
        //color: #555;
        //background-color: #fff;
        background-image: none;
        //border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
}

fieldset {
    padding-bottom: 50px;
    border: 0px !important;
}

.invalid {
    color: #a94442;
    border-left: 4px solid #a94442 !important;
}

label {
    border-left: 0px !important;
}

.ba-form {
    padding: 0px !important;
}

input[type=checkbox]
{
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    margin: 10px;
}
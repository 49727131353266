.obstannahme {
  position: relative;
  background-color: $secondbrand;
  padding: 20px;
  font-size: 16px;
  border-left: 40px solid darken($secondbrand, 20%);
  z-index: 1;

  .title {
    font-family: 'Lilita One', cursive;
    font-weight: 300;
    font-size: 20px;
  }
}

.obstannahme::before {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  color: $secondbrand;
  content: "\f05a";
  position: absolute;
  left: -35px;
  top: 50%;
  margin-top: -35px;
  z-index: 2;
}

.nav-container-header {
  margin-top: -1px;
  
  nav {
    background-color: transparent !important;
    padding: 0;
    font-size: 16px;

  }

  ul {
    margin: 0px;
    padding: 0px;

    li {
      display: inline;
    }
  }

  input {
    background-color: lighten($invert, 60);
    border-color: lighten($invert, 60);
    color: #FFFFFF;
    font-size: 26px !important;
    height: 50px !important;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #FFFFFF;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #FFFFFF;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #FFFFFF;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #FFFFFF;
  }

  img {
    max-width: 150px;
  }

  @media (min-width: $screen-md) {
    .hidden-md {
      display: none;
    }
  }
}

.offer-intro {
    background-color: #333333;
    color: #6e6e6e;
    padding: 20px;
    font-weight: 400;

    h5 {
        font-size: 30px;
    }
}

.offer-product {
    .offer-container {
        position: relative;

        img {
            max-height: 350px;
            text-align: center;
            padding: 0px 0 20px 0;
        }

        .offer-name {
            padding: 10px;
            font-weight: 100;
            font-size: 30px;
            padding-top: 0px;
            margin-top: -5px;
        }

        .offer-description {
            padding-bottom: 20px;
        }

        .offer-price {
            display: inline-block;
            padding: 7px;
            background-color: $invert;
            color: #FFFFFF;
            bottom: 0px;
            float: left;
            font-size: 16px;
        }

        .offer-details {
            float: left;

            .btn-info {
                background-color: $secondbrand;
                border-color: $secondbrand;
            }
        }

        @media (max-width: $screen-sm) {
            margin-bottom: 50px;
        }
    }
}

@media (max-width: $screen-md) {
    .offer-intro, .offer-description, .offer-price, .offer-details {
        font-size: 16px;

        a {
            font-size: 12px;
            line-height: 1.5;
            height: auto;
            padding: 10px;
            border: 0px;
        }
    }
}

@media (max-width: $screen-sm) {
    .offer-intro {
        display: none;
    }
}
